import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { Profile } from 'src/app/models/profile';
import { ChangePasswordComponent } from 'src/app/routes/profile/components/change-password/change-password.component';
import { EditProfileComponent } from 'src/app/routes/profile/components/edit-profile/edit-profile.component';
import { AuthService } from 'src/app/services/auth.service';
import { LanguagePreferenceService } from 'src/app/services/language-preference.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { UserService } from 'src/app/services/user.service';
import { ConfirmDialogComponent } from 'src/app/shares/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() toggleShowNav = new EventEmitter();
  token: string = '';
  authSubscribe;
  isAuth: boolean;
  profile: Profile;
  classCaption: string = "caption-sm";
  profileStyle: object = { border: '0' };
  className: string = 'title-lg';
  getLang: string = '';
  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private languagePreferenceService: LanguagePreferenceService
  ) {
    this.authSubscribe = authService.authChange$.subscribe((isAuth) => {
      this.isAuth = isAuth;
      if (this.isAuth) {
        this.userService.getProfile().subscribe((data) => {
          this.profile = data;
        });
      }
    });
  }

  ngOnInit(): void {
    this.token = this.localStorageService.get(LocalStorageEnum.token);
    this.updateLanguage();
  }

  getProfile() {
    this.userService.getProfile({}).subscribe((res) => {
      this.profile = res;
    });
  }

  navigateTo() {
    this.authService.navigateByRole().then(() => {
      window.location.reload();
    });
  }

  logout(): void {
    this.isAuth = false;
    this.authSubscribe.unsubscribe();
    this.authService.logout();
    window.location.replace("/login");
  }

  changePassword(): void {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      panelClass: 'small-modal'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //action after changed password succeed
      }
    });
  }

  openEditProfile(): void {
    const dialogRef = this.dialog.open(EditProfileComponent, {
      width: '587px',
      panelClass: 'small-modal',
      data: this.profile,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getProfile(); //update profile info
      }
    });
  }

  onDialogConfirmLogout(): void {
    const ref = this.dialog.open(ConfirmDialogComponent,
      {
        panelClass: 'small-modal',
        data: {
          title: 'Logout',
          icon: 'assets/svgs/mobile-logout.svg',
          message: 'ConfirmMessage.Logout',
          button: 'confirm'
        }
      },
    );
    ref.afterClosed().subscribe(
      (res) => {
        if (res == 'confirm') {
          this.logout();
        }
      }
    );
  }
  async updateLanguage() {
    try {
      const res = await this.languagePreferenceService
        .getPreference()
        .toPromise();
      if (res) {
        this.getLang = res?.data?.preference?.language;
        this.translateService.use(this.getLang);
        this.translateService.setDefaultLang(this.getLang);
        this.localStorageService.set(LocalStorageEnum.lang, this.getLang);
      }
    } catch (err) {
      console.error(err);
    }
  }
  async changeLanguagePreference() {
    const fromCurrentLang = this.translateService.currentLang;
    const toLang = fromCurrentLang === 'km' ? 'en' : 'km';
    const data = {
      language: toLang,
    };
    try {
      await this.languagePreferenceService.updatePreference(data).toPromise();
      // if not error, change language
      this.translateService.use(toLang);
      this.translateService.setDefaultLang(toLang);
      moment.locale(toLang)
    } catch (err) {
      console.error(err);
      // if got error, use current language
      this.translateService.use(fromCurrentLang);
      this.translateService.setDefaultLang(fromCurrentLang);
    }
  }
}
