<app-page-layout>
  <ng-container page-title>
    {{ "Dashboard.FarmerInfo" | translate }}
    <span *ngIf="filterData.start_date && filterData.end_date">
      ( {{ filterData.start_date | amDateFormat: "DD-MMM-YYYY" }}
      {{'ToDate' | translate }}
      {{ filterData.end_date | amDateFormat: "DD-MMM-YYYY" }} )
    </span>
  </ng-container>
  <ng-container header-action>
    <button class="btn-md btn-filter" mat-stroked-button color="primary" aria-label="Search" *ngIf="!isEntrepreneurRole"
      (click)="openFilterDialog()">
      <mat-icon svgIcon="icon-search" matPrefix></mat-icon>
      {{ "ActionBtn.Search" | translate }}
    </button>
    <button (click)="exportFile()" [disabled]="isLoading == true || dataCollection.length === 0" class="btn-md" mat-flat-button color="primary"
      aria-label="Export">
      <mat-icon svgIcon="icon-export" matPrefix></mat-icon>
      {{ "ActionBtn.Export" | translate }}
    </button>
  </ng-container>

  <div page-content class="table mat-elevation-z0" *ngIf="dataCollection?.length > 0">
    <app-farmer-table [dataCollection]="dataCollection" class="container-fluid"></app-farmer-table>
  </div>
  <div page-content class="data-not-found" *ngIf="dataCollection?.length <= 0 && !isLoading">
    <img src="../../../../../assets/svgs/data-not-found.svg" alt="Image not found resource 404" />
    <span>{{ "NoResult" | translate }}</span>
  </div>

  <app-pagination class="pagination" *ngIf="dataCollection?.length > 0" page-footer [total]="paginationData.total"
    [page]="paginationData.current_page" [limit]="paginationData.per_page" [surroundButton]="3"
    (changed)="goTo($event)"
  ></app-pagination>

  <div page-content *ngIf="isLoading" class="app-loading"></div>
</app-page-layout>