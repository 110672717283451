import { Component, Inject, Input, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import _ from 'lodash';
import moment from 'moment';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { TokenPipe } from 'src/app/shares/token-pipe/pipes/token.pipe';

const addresses = ['village', 'commune', 'district', 'province'];

@Component({
  selector: 'app-farmer-table',
  templateUrl: './farmer-table.component.html',
  styleUrls: ['./farmer-table.component.scss']
})
export class FarmerTableComponent implements OnInit {
  currentLanguage: string = 'km';
  dateFormatChange: string = 'DD / MMM / YYYY';
  dateFormat = 'YYYY-MM-DD';
  displayedColumns: string[] = [
    'id_card',
    'name',
    'dob',
    'photo',
    'address',
    'farmland',
    'action'
  ];
  columnGroups: string[] = [
    'f_id_card', 
    'f_name', 
    'f_dob', 
    'f_photo', 
    'ProductPage.Address', 
    'Dashboard.FarmlandInfo', 
    'f_action'
  ];
  trans: string[] = [];
  
  @Input() dataCollection: Array<any>;
  @Input() prefixLink: string;

  constructor(
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
  ) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = event.lang;
      moment.locale(event.lang);
      // Force refresh the data collection to update date formatting
      if (this.dataCollection) {
        this.dataCollection = [...this.dataCollection];
      }
      // Update translations when language changes
      this.getTranslations();
    });
  }

  ngOnInit(): void {
    moment.locale(this.translate.currentLang);
    this.currentLanguage = this.translate.currentLang;
    this.displayedColumns = [
      "id_card",
      'name',
      'dob',
      'photo',
      'FarmerPage.Village',
      'FarmerPage.CommuneSk',
      'FarmerPage.DistrictKh',
      'FarmerPage.Provice',
      'FarmlandPage.LandCode',
      'FarmlandPage.LandType',
      'FarmlandPage.LandSize',
      'action'
    ];
    this.getTranslations();
  }

  async getTranslations(): Promise<void> {
    this.trans = await this.translate
      .get([
        'No',
        'N/A',
        'Location.Location',
        'FarmlandPage.LandCode',
        'FarmlandPage.LandType',
        'FarmlandPage.LandSize',
        'FarmlandEnum.Type.PLANTATOIN',
        'FarmlandEnum.Type.RICE',
        'FarmlandEnum.Type.NET_HOUSE',
        'FarmlandEnum.Type.GREEN_HOUSE',
        'FarmlandEnum.Type.PLANTATION',
        'FarmlandEnum.Type.NATURAL_POND',
        'FarmlandEnum.Type.NEW_DIGGING_POND',
        'FarmlandEnum.Type.TENT_POND',
        'FarmlandEnum.Type.CEMENT_POND',
        'UnitType.Acres',
        'Address.Khan',
        'Address.Sangkat',
        'Address.District',
        'Address.Village',
        'Address.Commune',
        'Address.Province'
      ])
      .toPromise();
  }

  getFarmerAddressForDisplay(address, index) {
    return _.get(
      address, 
      this.currentLanguage === 'en' ? 
        `${addresses[index]}.name_en` : 
        `${addresses[index]}.name`, 
      this.trans['N/A']
    );
  }

  formatDate(date: string): string {
    if (!date) return this.trans['N/A'];
    return moment(date).format(this.dateFormatChange);
  }

  getFarmlandTypeLabel(index, farmland) {
    switch (index) {
      case 0:
        return farmland.code;
      case 1:
        return this.trans['FarmlandEnum.Type.' + farmland.type];
      default:
        return farmland.land_size + ' ' + this.trans['UnitType.Acres'];
    }
  }

  getImageUrl(imageUrl) {
    if (!imageUrl) return null;
    return new TokenPipe(this.localStorageService).transform(imageUrl);
  }

  getRouterLink(id) {
    if (!!this.prefixLink) return this.prefixLink + id;
    return id;
  }
}