import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx-js-style';
import { saveAs } from 'file-saver';

interface ExcelDownloadOptions {
  data: any[];
  headers: {
    main: string;
    subHeaders: string[];
    keys: string[];
    trans: string[];
  }[];
  fileName?: string;
  sheetName?: string;
  title?: string;
}
@Injectable({
  providedIn: 'root',
})
export class ExcelDownloadService {
  downloadExcel(options: ExcelDownloadOptions): void {
    try {
      const {
        data,
        headers,
        fileName = 'download.xlsx',
        sheetName = 'Sheet1',
        title = 'Report Title',
      } = options;

      const wb = XLSX.utils.book_new();

      const titleStyle = {
        font: { bold: true, size: 16, color: { rgb: 'FFFFFF' } },
        fill: { fgColor: { rgb: '0f562b' }, patternType: 'solid' },
        alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } },
        },
      };

      const mainHeaderStyle = {
        font: { bold: true, color: { rgb: 'FFFFFF' } },
        fill: { fgColor: { rgb: '0f562b' }, patternType: 'solid' },
        alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } },
        },
      };

      const subHeaderStyle = {
        font: { bold: true, color: { rgb: 'FFFFFF' } },
        fill: { fgColor: { rgb: '198A45' }, patternType: 'solid' },
        alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } },
        },
      };

      const dataCellStyle = {
        alignment: { horizontal: 'left', vertical: 'center', wrapText: true },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } },
        },
      };

      const createCell = (value: any) => ({
        v: value,
        t: typeof value === 'number' ? 'n' : 's',
        s: dataCellStyle,
      });

      // Create worksheet data
      const wsData: any[] = [];
      const merges: any[] = [];

      // Calculate total columns
      let totalColumns = 0;
      headers.forEach((header) => {
        totalColumns += header.subHeaders.length || 1;
      });

      // Add title row with merge
      const titleRow = [{ v: title, s: titleStyle }];
      for (let i = 1; i < totalColumns; i++) {
        titleRow.push({ v: '', s: titleStyle });
      }
      merges.push({
        s: { r: 0, c: 0 },
        e: { r: 0, c: totalColumns - 1 },
      });

      // Add headers
      const mainHeaderRow: any[] = [];
      const subHeaderRow: any[] = [];
      let colIndex = 0;

      headers.forEach((header) => {
        if (header.subHeaders.length === 0) {
          mainHeaderRow.push({ v: header.main, s: mainHeaderStyle });
          subHeaderRow.push({ v: '', s: mainHeaderStyle });
          merges.push({
            s: { r: 1, c: colIndex },
            e: { r: 2, c: colIndex },
          });
          colIndex += 1;
        } else {
          mainHeaderRow.push({ v: header.main, s: mainHeaderStyle });
          for (let i = 1; i < header.subHeaders.length; i++) {
            mainHeaderRow.push({ v: '', s: mainHeaderStyle });
          }
          if (header.subHeaders.length > 1) {
            merges.push({
              s: { r: 1, c: colIndex },
              e: { r: 1, c: colIndex + header.subHeaders.length - 1 },
            });
          }
          header.subHeaders.forEach((subHeader) => {
            subHeaderRow.push({ v: subHeader, s: subHeaderStyle });
          });
          colIndex += header.subHeaders.length;
        }
      });

      wsData.push(titleRow, mainHeaderRow, subHeaderRow);

      // Add data rows with switch case for handling different column types
      data.forEach((row, index) => {
        const baseRow: any[] = [];
        headers.forEach((header) => {
          switch (header.main) {
            case 'No.':
            case 'ល.រ':
              baseRow.push(createCell(index + 1));
              break;
            default:
              header.keys.forEach((key) => {
                baseRow.push(createCell(row[key]));
              });
              break;
          }
        });
        wsData.push(baseRow);
      });

      // Create worksheet
      const ws = XLSX.utils.aoa_to_sheet(wsData);

      // Set column widths and row heights
      ws['!cols'] = Array(totalColumns).fill({ wch: 20 });
      ws['!rows'] = wsData.map((_, i) => ({
        hpt: i === 0 ? 40 : i === 1 || i === 2 ? 30 : 25,
      }));

      // Set merge cells
      ws['!merges'] = merges;

      // Add worksheet to workbook and save
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'array',
      });

      const blob = new Blob([wbout], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, fileName);
    } catch (error) {
      console.error('Excel download failed:', error);
      throw new Error('Failed to download Excel file');
    }
  }
}
