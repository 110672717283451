import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/profile';
import { BaseCrudService } from './base-crud.service';

export interface ReferenceResponse {
  status?: number;
  data?: {
    _id?: string;
    preference?: {
      language?: string;
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class LanguagePreferenceService extends BaseCrudService<User> {
  constructor(injector: Injector) {
    super(injector);
    this.path = environment.portal_url;
  }

  getPreference(): Observable<ReferenceResponse> {
    return this.requestService.get(this.path + '/users/preference');
  }

  updatePreference(data: {}): Observable<ReferenceResponse> {
    return this.requestService.postJSON(this.path + '/users/preference', {data});
  }

}