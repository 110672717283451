import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { KumrongService } from 'src/app/services/kumrong.service';
import { AnnualHarvestYieldReport } from 'src/app/models/annual-harvest-yield-report';
import { formatNumber } from '@angular/common';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import _ from 'lodash';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ExcelDownloadService } from './service/annual-yield-excel';

@Component({
  selector: 'app-annual-yield',
  templateUrl: './annual-yield.component.html',
  styleUrls: ['./annual-yield.component.scss'],
})
export class AnnualYieldComponent implements OnInit {
  form: FormGroup;
  displayedColumns = [
    'position',
    'crop_name',
    'total_estimation_yield',
    'total_actual_yield',
  ];

  annualYields: AnnualHarvestYieldReport[] = [];
  dataSource = [];
  columnGroups: string[] = [];
  isLoading: boolean = false;

  params = {
    start_date: null,
    end_date: null,
  };

  stickyColumns = [
    'position',
    'crop_name',
    'total_estimation_yield',
    'total_actual_yield',
  ];

  trans: string[] = [];
  BOM = '\uFEFF';

  constructor(
    private fb: FormBuilder,
    private kumroungService: KumrongService,
    private translateService: TranslateService,
    private excelService: ExcelDownloadService
  ) {
    this.form = this.fb.group({
      startDate: [''],
      endDate: [''],
    });

    // Update translations and regenerate columns when language changes
    this.translateService.onLangChange.subscribe(
      async (event: LangChangeEvent) => {
        moment.locale(event.lang);
        await this.getTranslations();

        // Regenerate column groups with new locale without fetching data
        if (this.annualYields.length > 0) {
          this.resetData();
          this._generateColumnGroups();
          this._generateData();
        }
      }
    );
  }

  async ngOnInit(): Promise<void> {
    moment.locale(this.translateService.currentLang);
    await this.getTranslations();
    await this._fetchDataAndUpdateTable();
  }

  async getTranslations(): Promise<void> {
    this.trans = await this.translateService
      .get([
        'No',
        'Crop.Name',
        'HarvestEstimation.TotalEstimationYield',
        'HarvestEstimation.TotalActualYield',
        'HarvestEstimation.Estimation',
        'HarvestEstimation.Actual',
        'HarvestEstimation.Note',
        'Month.January',
        'Month.February',
        'Month.March',
        'Month.April',
        'Month.May',
        'Month.June',
        'Month.July',
        'Month.August',
        'Month.September',
        'Month.October',
        'Month.November',
        'Month.December',
        'HarvestEstimation.ReportAnnualYield',
        'InKilo',
      ])
      .toPromise();
  }

  private async _fetchDataAndUpdateTable() {
    try {
      this.isLoading = true;
      this.annualYields = (
        await this.kumroungService
          .getAnnualHarvestYieldReport(this.params)
          .toPromise()
      ).data;

      this.resetData();
      this._generateColumnGroups();
      this._generateData();

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    }
  }

  resetData() {
    this.displayedColumns = [
      'position',
      'crop_name',
      'total_estimation_yield',
      'total_actual_yield',
    ];

    this.dataSource = [];
    this.columnGroups = [];
  }

  onDateChange(field: string, event: any) {
    this.params[field] = event.value
      ? moment(event.value).locale('en').format('YYYY-MM-DD')
      : null;
    if (
      this.params.start_date &&
      this.params.start_date != '' &&
      this.params.end_date &&
      this.params.end_date != ''
    ) {
      this._fetchDataAndUpdateTable();
    }
  }

  private _generateData() {
    this.dataSource = this.annualYields.map((any) => {
      let data = {};

      data['crop_name'] = any.crop?.name_km;
      data['total_estimation_yield'] = formatNumber(
        any?.total_estimate_harvest_quantity,
        'en',
        '1.0-2'
      );
      data['total_actual_yield'] = formatNumber(
        any?.total_harvest_quantity,
        'en',
        '1.0-2'
      );

      let eachYears = _.uniq(any.data.map((d) => d.year));

      for (let i = 0; i < eachYears.length; i++) {
        let years12 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        years12.forEach((yData, idx) => {
          let dataIdx = any.data.findIndex(
            (dt) => dt.month === yData && dt.year === eachYears[i]
          );

          if (dataIdx != -1) {
            data[`Estimation_${idx}_${eachYears[i]}`] = formatNumber(
              any.data[dataIdx]?.total_estimate_harvest_quantity,
              'en',
              '1.0-2'
            );
            data[`Actual_${idx}_${eachYears[i]}`] = formatNumber(
              any.data[dataIdx]?.total_harvest_quantity,
              'en',
              '1.0-2'
            );
          } else {
            data[`Estimation_${idx}_${eachYears[i]}`] = 0;
            data[`Actual_${idx}_${eachYears[i]}`] = 0;
          }
        });
      }

      return data;
    });
  }

  private _generateColumnGroups() {
    if (this.annualYields.length) {
      const dataIndexes = this.annualYields.map((d) => d?.data?.length);
      let maxIndex = dataIndexes.indexOf(Math.max(...dataIndexes));
      let eachYears = _.uniq(
        this.annualYields[maxIndex]?.data?.map((dd) => dd.year)
      );

      for (let i = 0; i < eachYears.length; i++) {
        let years12 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
        years12.forEach((yData, idx) => {
          this.displayedColumns.push(`Estimation_${idx}_${eachYears[i]}`);
          this.displayedColumns.push(`Actual_${idx}_${eachYears[i]}`);
          // Use moment with current language for month names
          const monthName = moment()
            .locale(this.translateService.currentLang)
            .month(idx)
            .format('MMMM');
          this.columnGroups.push(`${monthName}_${eachYears[i]}`);
        });
      }

      this.columnGroups = [
        'no',
        'crop_n',
        'total_e',
        'total_a',
        ...this.columnGroups,
      ];
    }
  }

  exportFile() {
    const date = new Date();
    const monthTranslations = [
      'Month.January',
      'Month.February',
      'Month.March',
      'Month.April',
      'Month.May',
      'Month.June',
      'Month.July',
      'Month.August',
      'Month.September',
      'Month.October',
      'Month.November',
      'Month.December',
    ];

    const headerStructure = [
      {
        main: this.trans['No'],
        subHeaders: [],
        keys: [],
        trans: [],
      },
      {
        main: this.trans['Crop.Name'],
        subHeaders: [],
        keys: ['crop_name'],
        trans: [],
      },
      {
        main: this.trans['HarvestEstimation.TotalEstimationYield'],
        subHeaders: [],
        keys: ['total_estimation_yield'],
        trans: [],
      },
      {
        main: this.trans['HarvestEstimation.TotalActualYield'],
        subHeaders: [],
        keys: ['total_actual_yield'],
        trans: [],
      },
      ...monthTranslations.map((monthTrans, index) => ({
        main: this.trans[monthTrans],
        subHeaders: [
          this.trans['HarvestEstimation.Estimation'],
          this.trans['HarvestEstimation.Actual'],
        ],
        keys: [
          `Estimation_${index}_${date.getFullYear()}`,
          `Actual_${index}_${date.getFullYear()}`,
        ],
        trans: [],
      })),
    ];

    this.excelService.downloadExcel({
      data: this.dataSource,
      headers: headerStructure,
      fileName: `collection-annual-yield-${moment()
        .locale('en')
        .format('YYYY-MM-DD-hh-mm')}.xlsx`,
      title: `   ${this.trans['HarvestEstimation.ReportAnnualYield']} ( ${this.trans['InKilo']} )`,
    });
  }

  onResetForm(
    startDateInput: HTMLInputElement,
    endDateInput: HTMLInputElement
  ): void {
    startDateInput.value = '';
    endDateInput.value = '';
    this.params.start_date = null;
    this.params.end_date = null;
    this.form.reset();
    this.clearDateFields();
    this._fetchDataAndUpdateTable();
  }

  private clearDateFields(): void {
    this.params = {
      start_date: null,
      end_date: null,
    };
  }
}
