import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService, LangChangeEvent} from '@ngx-translate/core';
import _ from 'lodash';
import { Pagination } from 'src/app/models/pagination';
import { FarmersService } from './services/farmers.service';
import { TokenPipe } from 'src/app/shares/token-pipe/pipes/token.pipe';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { FilterFarmersDialogComponent } from './components/filter-farmer-dialog/filter-farmers-dialog.component';
import { ExcelDownloadService } from './services/farmer-excel.service';
import * as moment from 'moment';
@Component({
  selector: 'app-farmers',
  templateUrl: './farmers.component.html',
  styleUrls: ['./farmers.component.scss'],
})
export class FarmersComponent implements OnInit {
  isLoading: Boolean = false;
  dataCollection: Array<any>;
  isEntrepreneurRole: boolean = false;
  currentLanguage: string = 'km';
  paginationData = {
    per_page: 10,
    current_page: 1,
    total: 10,
  };
  dateFormat = 'YYYY-MM-DD';
  filterData = {
    page_number: 1,
    per_page: 10,
    start_date: null,
    end_date: null,
    province: 0,
    district: 0,
    commune: 0,
    village: 0,
    id_card: null,
    first_name: null,
    last_name: null,
    includes: ['farmland'],
  };
  displayedColumns: string[] = [
    'id_card',
    'name',
    'dob',
    'photo',
    'address',
    'farmland',
    'action',
  ];
  columnGroups: string[] = [
    'f_id_card',
    'f_name',
    'f_dob',
    'f_photo',
    'f_address',
    'Dashboard.FarmlandInfo',
    'f_action',
  ];
  trans: string[] = [];

  constructor(
    private translate: TranslateService,
    private service: FarmersService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private router: Router,
    private excelService: ExcelDownloadService
  ) {
    // Subscribe to language changes
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = event.lang;
      // Update translations when language changes
      this.getTranslations();
    });
  }

  ngOnInit(): void {
    let currentUrl = this.router.url;
    let previousUrl = '';
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        previousUrl = currentUrl;
        currentUrl = event.url;
        if (currentUrl == '/farmers' && !previousUrl.startsWith('/farmers/')) {
          this.localStorageService.delete(LocalStorageEnum.farmer_table_filter);
        }
      }
    });

    // Clear the selected filter value when closing browser tab or logging out
    window.onbeforeunload = () => {
      this.localStorageService.delete(LocalStorageEnum.farmer_table_filter);
    };

    this.currentLanguage = this.translate.currentLang;
    this.displayedColumns = [
      'id_card',
      'name',
      'dob',
      'photo',
      'address',
      'FarmlandPage.LandCode',
      'FarmlandPage.LandType',
      'FarmlandPage.LandSize',
      'action',
    ];
    this.getTranslations();

    let selectedFilter = JSON.parse(
      this.localStorageService.get(LocalStorageEnum.farmer_table_filter)
    );
    if (!!selectedFilter) this.filterData = selectedFilter;

    this.getFarmerList();
    this.getTranslations();
  }

  async getTranslations(): Promise<void> {
    this.trans = await this.translate
      .get([
        'No',
        'N/A',
        'Location.Location',
        'FarmlandPage.LandCode',
        'FarmlandPage.LandType',
        'FarmlandPage.LandSize',
        'FarmlandEnum.Type.PLANTATOIN',
        'FarmlandEnum.Type.RICE',
        'FarmlandEnum.Type.NET_HOUSE',
        'FarmlandEnum.Type.GREEN_HOUSE',
        'FarmlandEnum.Type.PLANTATION',
        'FarmlandEnum.Type.NATURAL_POND',
        'FarmlandEnum.Type.NEW_DIGGING_POND',
        'FarmlandEnum.Type.TENT_POND',
        'FarmlandEnum.Type.CEMENT_POND',
        'UnitType.Acres',
        'Address.Khan',
        'Address.Sangkat',
        'Address.District',
        'Address.Village',
        'Address.Commune',
        'Address.Province',
        'FarmerPage.IdCard',
        'ReportPage.FarmerName',
        'FarmerMemberInfo.DateOfBirth',
        'FarmlandPage.Address',
        'FarmlandPage.Farm',
        'FarmerPage.DistrictKh',
        'FarmerPage.CommuneSk',
        'FarmerPage.Village',
        'FarmerPage.Provice',
        'ReportPage.FarmerInfo'
      ])
      .toPromise();
  }

  getFarmerList(): void {
    this.isLoading = true;
    this.service.getFarmers(this.filterData).subscribe(
      (res) => {
        this.isLoading = false;
        this.dataCollection = res.data.data;
        this.paginationData.per_page = res.data?.pagination?.per_page;
        this.paginationData.total = res.data?.pagination?.total;
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  resetPagination() {
    this.paginationData.current_page = 1;
    this.paginationData.per_page = 10;
    this.filterData.page_number = 1;
    this.filterData.per_page = 10;
  }

  openFilterDialog(): void {
    const dialogRef = this.dialog.open(FilterFarmersDialogComponent, {
      panelClass: ['medium-modal', 'modal-with-tab'],
      data: this.filterData,
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.resetPagination();
        this.filterData = data.data;
        this.filterData.includes = ["farmland"];
        this.getFarmerList();
        this.localStorageService.set(
          LocalStorageEnum.farmer_table_filter,
          JSON.stringify(this.filterData)
        );
      }
    });
  }

  goTo(event: Pagination) {
    this.paginationData.per_page = event.per_page;
    this.paginationData.current_page = event.current_page;
    this.filterData.page_number = event.current_page;
    this.filterData.per_page = event.per_page;
    this.getFarmerList();
  }

  getFarmerAddressForDisplay(address, type) {
    return _.get(
      address,
      this.currentLanguage == 'en' ? `${type}.name_en` : `${type}.name`,
      this.trans['N/A']
    );
  }

  getFarmlandTypeLabel(index, farmland) {
    switch (index) {
      case 0:
        return farmland.code;
        break;
      case 1:
        return this.trans['FarmlandEnum.Type.' + farmland.type];
        break;
      default:
        return farmland.land_size + ' ' + this.trans['UnitType.Acres'];
    }
  }

  getImageUrl(imageUrl) {
    if (!imageUrl) return null;

    return new TokenPipe(this.localStorageService).transform(imageUrl);
  }
  exportFile() {
    // Ensure translations are up to date before export
    this.getTranslations().then(() => {
      const headerStructure = [
        {
          main: this.trans['No'],
          subHeaders: [],
          keys: [],
          trans: [],
        },
        {
          main: this.trans['FarmerPage.IdCard'],
          subHeaders: [],
          keys: ['id_card'],
          trans: [],
        },
        {
          main: this.trans['ReportPage.FarmerName'],
          subHeaders: [],
          keys: ['first_name', 'last_name'],
          trans: [],
        },
        {
          main: this.trans['FarmerMemberInfo.DateOfBirth'],
          subHeaders: [],
          keys: ['dob'],
          trans: [],
        },
        {
          main: this.trans['FarmlandPage.Address'],
          subHeaders: [
            this.trans['FarmerPage.Village'],
            this.trans['FarmerPage.CommuneSk'],
            this.trans['FarmerPage.DistrictKh'],
            this.trans['Address.Province'],
          ],
          keys: ['address'],
          trans: [],
        },
        {
          main: this.trans['FarmlandPage.Farm'],
          subHeaders: [
            this.trans['FarmlandPage.LandCode'],
            this.trans['FarmlandPage.LandType'],
            this.trans['FarmlandPage.LandSize'],
          ],
          keys: ['farmland'],
          trans: [
            this.trans['FarmlandEnum.Type.PLANTATOIN'],
            this.trans['FarmlandEnum.Type.RICE'],
            this.trans['FarmlandEnum.Type.NET_HOUSE'],
            this.trans['FarmlandEnum.Type.GREEN_HOUSE'],
            this.trans['FarmlandEnum.Type.PLANTATION'],
            this.trans['FarmlandEnum.Type.NATURAL_POND'],
            this.trans['FarmlandEnum.Type.NEW_DIGGING_POND'],
            this.trans['FarmlandEnum.Type.TENT_POND'],
            this.trans['FarmlandEnum.Type.CEMENT_POND'],
          ],
        },
      ];

      this.excelService.downloadExcel({
        data: this.dataCollection,
        headers: headerStructure,
        fileName: `collection-farmer-info-${moment().locale('en').format('YYYY-MM-DD-hh-mm')}.xlsx`,
        title: this.trans['ReportPage.FarmerInfo'],
        currentLang: this.currentLanguage // Use the current language property
      });
    });
  }
}
