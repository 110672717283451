import { Component, OnInit, OnDestroy } from '@angular/core';
import moment from 'moment';
import { KumrongService } from 'src/app/services/kumrong.service';
import { formatNumber } from '@angular/common';
import { ProvincialHarvestYieldReport } from 'src/app/models/provincial-harvest-yield-report';
import { Province } from 'src/app/models/address';
import { ProvinceService } from 'src/app/services/province.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ExcelDownloadService } from './service/provincial-yiel-excel';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-provincial-yield',
  templateUrl: './provincial-yield.component.html',
  styleUrls: ['./provincial-yield.component.scss'],
})
export class ProvincialYieldComponent implements OnInit, OnDestroy {
  form: FormGroup;
  displayedColumns = [
    'position',
    'crop_name',
    'total_estimation_yield',
    'total_actual_yield',
  ];

  provincialYields: ProvincialHarvestYieldReport[] = [];
  dataSource = [];
  columnGroups: string[] = [];
  isLoading: boolean = false;
  provinces: Province[] = [];
  currentLanguage: string;
  langChangeSubscription: Subscription;

  params = {
    start_date: null,
    end_date: null,
    province: 0,
  };

  trans: { [key: string]: string } = {};
  BOM = '\uFEFF';

  constructor(
    private fb: FormBuilder,
    private kumroungService: KumrongService,
    private provinceService: ProvinceService,
    private translateService: TranslateService,
    private excelService: ExcelDownloadService
  ) {
    this.form = this.fb.group({
      startDate: [''],
      endDate: [''],
    });
    this.initializeLanguage();
  }

  initializeLanguage(): void {
    this.currentLanguage =
      this.translateService.currentLang ||
      this.translateService.getDefaultLang();

    this.langChangeSubscription = this.translateService.onLangChange.subscribe(
      async (event: LangChangeEvent) => {
        this.currentLanguage = event.lang;
        await this.getTranslations();
        // Regenerate column groups without fetching data again
        this.resetData();
        this._generateColumnGroups();
        this._generateData();
      }
    );
  }

  async ngOnInit(): Promise<void> {
    await this.getTranslations();
    await this.getProvinces();
    await this._fetchDataAndUpdateTable();
  }

  ngOnDestroy(): void {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  async getTranslations(): Promise<void> {
    const translationKeys = [
      'No',
      'Tons',
      'Crop.Name',
      'HarvestEstimation.TotalEstimationYield',
      'HarvestEstimation.TotalActualYield',
      'HarvestEstimation.Estimation',
      'HarvestEstimation.Actual',
      'HarvestEstimation.Note',
      'HarvestEstimation.ReportProvincialYield',
      'InKilo',
    ];

    this.trans = await this.translateService.get(translationKeys).toPromise();
  }

  async getProvinces() {
    this.isLoading = true;
    try {
      this.provinces = (
        await this.provinceService.getDropDownProvince().toPromise()
      ).data;
      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;
      console.error(err);
    }
  }

  private async _fetchDataAndUpdateTable() {
    try {
      this.isLoading = true;
      this.provincialYields = (
        await this.kumroungService
          .getProvincialHarvestYieldReport(this.params)
          .toPromise()
      ).data;

      this.resetData();
      this._generateColumnGroups();
      this._generateData();

      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.error(error);
    }
  }

  resetData() {
    this.displayedColumns = [
      'position',
      'crop_name',
      'total_estimation_yield',
      'total_actual_yield',
    ];

    this.dataSource = [];
    this.columnGroups = [];
  }

  onSelectChange(event: any) {
    this.params.province = event?.value ?? 0;
    this._fetchDataAndUpdateTable();
  }

  onDateChange(field: string, event: any) {
    this.params[field] = event.value
      ? moment(event.value).locale('en').format('YYYY-MM-DD')
      : null;
    if (
      this.params.start_date &&
      this.params.start_date != '' &&
      this.params.end_date &&
      this.params.end_date != ''
    ) {
      this._fetchDataAndUpdateTable();
    }
  }

  private _generateData() {
    this.dataSource = this.provincialYields.map((any) => {
      let data: any = {
        crop_name: this.currentLanguage === 'en' ? any.crop?.name_en : any.crop?.name_km,
        total_estimation_yield: formatNumber(
          any?.total_estimate_harvest_quantity,
          'en',
          '1.0-2'
        ),
        total_actual_yield: formatNumber(
          any?.total_harvest_quantity,
          'en',
          '1.0-2'
        ),
        provinces: [],
      };

      any.data.forEach((dd) => {
        data['provinces'].push(dd.province);
        data[`Estimation_${dd.province?._id}`] = formatNumber(
          dd?.total_estimate_harvest_quantity,
          'en',
          '1.0-2'
        );
        data[`Actual_${dd.province?._id}`] = formatNumber(
          dd?.total_harvest_quantity,
          'en',
          '1.0-2'
        );
      });

      return data;
    });
  }

  private _generateColumnGroups() {
    if (this.provincialYields.length) {
      const dataIndexes = this.provincialYields.map((d) => d?.data?.length);
      let maxIndex = dataIndexes.indexOf(Math.max(...dataIndexes));

      this.provincialYields[maxIndex].data.forEach((dt) => {
        this.displayedColumns.push(`Estimation_${dt.province?._id}`);
        this.displayedColumns.push(`Actual_${dt.province?._id}`);
        this.columnGroups.push(
          this.currentLanguage === 'en'
            ? dt.province?.name_en
            : dt.province?.name
        );
      });

      this.columnGroups = [
        'no',
        'crop_n',
        'total_e',
        'total_a',
        ...this.columnGroups,
      ];
    }
  }

  private findBiggestProvinces(): Province[] {
    if (!this.dataSource || this.dataSource.length === 0) {
      return [];
    }

    const itemWithMostProvinces = this.dataSource.reduce(
      (maxItem, currentItem) => {
        const maxLength = maxItem.provinces?.length || 0;
        const currentLength = currentItem.provinces?.length || 0;
        return currentLength > maxLength ? currentItem : maxItem;
      },
      this.dataSource[0]
    );

    return itemWithMostProvinces.provinces || [];
  }

  async exportFile() {
    await this.getTranslations();

    const biggestLenghtOfProvince = this.findBiggestProvinces();

    const headerStructure = [
      {
        main: this.trans['No'],
        subHeaders: [],
        keys: [],
        trans: [],
      },
      {
        main: this.trans['Crop.Name'],
        subHeaders: [],
        keys: ['crop_name'],
        trans: [],
      },
      {
        main: this.trans['HarvestEstimation.TotalEstimationYield'],
        subHeaders: [],
        keys: ['total_estimation_yield'],
        trans: [],
      },
      {
        main: this.trans['HarvestEstimation.TotalActualYield'],
        subHeaders: [],
        keys: ['total_actual_yield'],
        trans: [],
      },
    ];
    
    this.dataSource = this.dataSource.map((item) => {
      biggestLenghtOfProvince.forEach((province: Province) => {
        if (item[`Estimation_${province._id}`] === undefined)
          item[`Estimation_${province._id}`] = '0';
        if (item[`Actual_${province._id}`] === undefined)
          item[`Actual_${province._id}`] = '0';
      });
      return item;
    });

    biggestLenghtOfProvince.forEach((province: Province) => {
      headerStructure.push({
        main: this.currentLanguage === 'en' ? province.name_en : province.name,
        subHeaders: [
          this.trans['HarvestEstimation.Estimation'],
          this.trans['HarvestEstimation.Actual'],
        ],
        keys: [`Estimation_${province._id}`, `Actual_${province._id}`],
        trans: [],
      });
    });

    this.excelService.downloadExcel({
      data: this.dataSource,
      headers: headerStructure,
      fileName: `collection-provincial-yield-info-${moment()
        .locale('en')
        .format('YYYY-MM-DD-hh-mm')}.xlsx`,
      title: `${this.trans['HarvestEstimation.ReportProvincialYield']} (${this.trans['InKilo']})`,
    });
  }

  getProvince(province): string {
    return this.currentLanguage === 'en' ? province?.name_en : province?.name;
  }

  onResetForm(
    startDateInput: HTMLInputElement,
    endDateInput: HTMLInputElement
  ): void {
    startDateInput.value = '';
    endDateInput.value = '';
    this.params.start_date = null;
    this.params.end_date = null;
    this.form.reset();
    this.clearDateFields();
    this._fetchDataAndUpdateTable();
  }

  private clearDateFields(): void {
    this.params = {
      start_date: null,
      end_date: null,
      province: 0,
    };
  }
}