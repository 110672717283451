import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx-js-style';
import { saveAs } from 'file-saver';

interface ExcelDownloadOptions {
  data: any[];
  headers: {
    main: string;
    subHeaders: string[];
    keys: string[];
    trans: string[];
  }[];
  fileName?: string;
  sheetName?: string;
  title?: string;
  currentLang?: string;
}
@Injectable({
  providedIn: 'root',
})
export class ExcelDownloadService {
  downloadExcel(options: ExcelDownloadOptions): void {
    try {
      const {
        data,
        headers,
        fileName = 'download.xlsx',
        sheetName = 'Sheet1',
        title = 'បញ្ជីឈ្មោះកសិករ', // Default title in Khmer
        currentLang,
      } = options;

      // Create workbook
      const wb = XLSX.utils.book_new();
      // Styles
      const titleStyle = {
        font: { bold: true, size: 16, color: { rgb: 'FFFFFF' } },
        fill: { fgColor: { rgb: '0f562b' }, patternType: 'solid' },
        alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } },
        },
      };

      const mainHeaderStyle = {
        font: { bold: true, color: { rgb: 'FFFFFF' } },
        fill: { fgColor: { rgb: '0f562b' }, patternType: 'solid' },
        alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } },
        },
      };

      const subHeaderStyle = {
        font: { bold: true, color: { rgb: 'FFFFFF' } },
        fill: { fgColor: { rgb: '198A45' }, patternType: 'solid' },
        alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } },
        },
      };

      const dataCellStyle = {
        alignment: { vertical: 'center', wrapText: true },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } },
        },
      };

      // Helper functions
      const createCell = (value: any, style: any = dataCellStyle) => ({
        v: value,
        t: typeof value === 'number' ? 'n' : 's',
        s: style,
      });

      const getCombinedNameValue = (row: any, keys: string[]) => {
        const firstName = row[keys[0]] || '';
        const lastName = row[keys[1]] || '';
        return ` ${lastName} ${firstName} `.trim();
      };

      const formateDate = (row: any) => {
        const newDate = new Date(row);
        return newDate.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      };

      // Create worksheet data
      const wsData: any[] = [];
      const merges: any[] = [];

      // Calculate total columns
      let totalColumns = 0;
      headers.forEach((header) => {
        totalColumns += header.subHeaders.length || 1;
      });

      // Add title row with merge
      const titleRow = [{ v: title, s: titleStyle }];
      for (let i = 1; i < totalColumns; i++) {
        titleRow.push({ v: '', s: titleStyle });
      }
      merges.push({
        s: { r: 0, c: 0 },
        e: { r: 0, c: totalColumns - 1 },
      });

      // Add headers
      const mainHeaderRow: any[] = [];
      const subHeaderRow: any[] = [];
      let colIndex = 0;

      headers.forEach((header) => {
        if (header.subHeaders.length === 0) {
          mainHeaderRow.push(createCell(header.main, mainHeaderStyle));
          subHeaderRow.push(createCell('', mainHeaderStyle));
          merges.push({
            s: { r: 1, c: colIndex },
            e: { r: 2, c: colIndex },
          });
          colIndex += 1;
        } else {
          mainHeaderRow.push(createCell(header.main, mainHeaderStyle));
          for (let i = 1; i < header.subHeaders.length; i++) {
            mainHeaderRow.push(createCell('', mainHeaderStyle));
          }
          if (header.subHeaders.length > 1) {
            merges.push({
              s: { r: 1, c: colIndex },
              e: { r: 1, c: colIndex + header.subHeaders.length - 1 },
            });
          }
          header.subHeaders.forEach((subHeader) => {
            subHeaderRow.push(createCell(subHeader, subHeaderStyle));
          });
          colIndex += header.subHeaders.length;
        }
      });

      wsData.push(titleRow, mainHeaderRow, subHeaderRow);

      // Add data rows with spanning
      let currentRow = 3; // Start after title and headers
      data.forEach((row, index) => {
        const farmlands = row.farmlands || [];
        const farmlandCount = Math.max(1, farmlands.length);

        // Create base row data
        const baseRow: any[] = [];
        headers.forEach((header) => {
          switch (header.main) {
            case 'No.':
            case 'ល.រ':
              baseRow.push(createCell(index + 1));
              break;
            case 'Farmer Name':
            case 'ឈ្មោះកសិករ':
              if (header.keys.length === 2) {
                baseRow.push(
                  createCell(getCombinedNameValue(row, header.keys))
                );
              } else {
                header.keys.forEach((key) => {
                  baseRow.push(createCell(row[key]));
                });
              }
              break;
            case 'Date Of Birth':
            case 'ថ្ងៃខែឆ្នាំកំណើត':
              header.keys.forEach((key) => {
                baseRow.push(createCell(formateDate(row[key])));
              });
              break;
            case 'Address':
            case 'អាសយដ្ឋាន':
              const addressFields = [
                'village',
                'commune',
                'district',
                'province',
              ];
              header.subHeaders.forEach((_, index) => {
                const field = addressFields[index];
                header.keys.forEach((key) => {
                  currentLang == 'en'
                    ? baseRow.push(createCell(row[key]?.[field]?.name_en || ''))
                    : baseRow.push(createCell(row[key]?.[field]?.name || ''));
                });
              });
              break;
            case 'Farmland':
            case 'ដីកសិកម្ម':
              if (farmlands.length > 0) {
                farmlands.forEach((farmland: any, fIndex: number) => {
                  const rowData = [...baseRow];
                  rowData.push(createCell(farmland.code));
                  switch (farmland.type) {
                    case 'PLANTATOIN':
                      rowData.push(header.trans[0]);
                      break;
                    case 'RICE':
                      rowData.push(header.trans[1]);
                      break;
                    case 'NET_HOUSE':
                      rowData.push(header.trans[2]);
                      break;
                    case 'GREEN_HOUSE':
                      rowData.push(header.trans[3]);
                      break;
                    case 'PLANTATION':
                      rowData.push(header.trans[4]);
                      break;
                    case 'NATURAL_POND':
                      rowData.push(header.trans[5]);
                      break;
                    case 'NEW_DIGGING_POND':
                      rowData.push(header.trans[6]);
                      break;
                    case 'TENT_POND':
                      rowData.push(header.trans[7]);
                      break;
                    case 'CEMENT_POND':
                      rowData.push(header.trans[8]);
                      break;
                  }
                  rowData.push(
                    createCell(
                      `${farmland.land_size} ${
                        currentLang == 'en' ? 'Acres' : 'ម៉ែត្រការ៉េ'
                      }`
                    )
                  );
                  wsData.push(rowData);
                });

                // Add vertical merges for user info if multiple farmlands
                if (farmlands.length > 1) {
                  const mergeCols = baseRow.length;
                  for (let col = 0; col < mergeCols; col++) {
                    merges.push({
                      s: { r: currentRow, c: col },
                      e: { r: currentRow + farmlands.length - 1, c: col },
                    });
                  }
                }
              } else {
                wsData.push(baseRow);
              }
              currentRow += farmlandCount;
              break;
            default:
              if (header.subHeaders.length === 0) {
                baseRow.push(createCell(row[header.keys[0]]));
              } else {
                header.keys.forEach((key) => {
                  baseRow.push(createCell(row[key]));
                });
              }
              break;
          }
        });
      });

      // Create worksheet
      const ws = XLSX.utils.aoa_to_sheet(wsData);

      // Set column widths and row heights
      ws['!cols'] = Array(colIndex).fill({ wch: 25 });
      ws['!rows'] = wsData.map((_, i) => ({
        hpt: i === 0 ? 40 : i === 1 || i === 2 ? 30 : 25,
      }));

      // Set merge cells
      ws['!merges'] = merges;

      // Add worksheet to workbook and save
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'array',
      });

      const blob = new Blob([wbout], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, fileName);
    } catch (error) {
      console.error('Excel download failed:', error);
      throw new Error('Failed to download Excel file');
    }
  }
}
