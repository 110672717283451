<app-page-layout>
  <ng-container page-title>
    {{ 'HarvestEstimation.ReportProvincialYield' | translate }}({{ 'InKilo' | translate }})
  </ng-container>
  <ng-container header-action>
    <mat-form-field appearance="outline" class="custom-date-range-picker fiter-date-range">
      <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <mat-date-range-input [rangePicker]="picker" [formGroup]="form">
        <input #startDateInput formControlName="startDate" (click)="picker.open()" (dateChange)="onDateChange('start_date', $event)" matStartDate
          placeholder="{{'StartDate' | translate}}">
        <input #endDateInput formControlName="endDate" (click)="picker.open()" (dateChange)="onDateChange('end_date', $event)" matEndDate
          placeholder="{{'EndDate' | translate}}">
      </mat-date-range-input>

    </mat-form-field>

    <button mat-stroked-button class="btn-location">
      <mat-icon class="svg-path svg-fill" svgIcon="icon-locate"></mat-icon>
      <mat-select class="province-custom-select" (selectionChange)="onSelectChange($event)" [(value)]="params.province">
        <mat-option [value]="0">
          {{'AllProvinces' | translate}}
        </mat-option>
        <mat-option *ngFor="let province of provinces" [value]="province?._id">{{ getProvince(province) }}</mat-option>
      </mat-select>
    </button>

    <button mat-button (click)="onResetForm(startDateInput, endDateInput)" class="btn-reset" mat-flat-button >
      <mat-icon>autorenew</mat-icon>
      {{ "ActionBtn.Resets" | translate }}
    </button>

    <button (click)="exportFile()" [disabled]="isLoading == true || dataSource.length === 0" class="btn-md" mat-flat-button color="primary"
      aria-label="Export">
      <mat-icon svgIcon="icon-export" matPrefix></mat-icon>
      {{ "ActionBtn.Export" | translate }}
    </button>
  </ng-container>

  <div page-content class="table mat-elevation-z0" *ngIf="dataSource?.length > 0">
    <table class="harvest-est-table" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="position">
        <th [ngStyle]="{'display': 'none'}" mat-header-cell width="150" *matHeaderCellDef class="header-center">
          {{ "No" | translate }}
        </th>
        <td mat-cell *matCellDef="let element; let i = index" align="center">
          {{ i + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="no">
        <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">{{ "No" | translate }}</th>
      </ng-container>

      <ng-container matColumnDef="crop_name">
        <th mat-header-cell *matHeaderCellDef [ngStyle]="{'display': 'none'}">
          {{ "Crop.Name" | translate }}
        </th>
        <td mat-cell mat-cell *matCellDef="let element">
          {{ element.crop_name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="crop_n">
        <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">{{ "Crop.Name" | translate }}</th>
      </ng-container>

      <ng-container matColumnDef="total_estimation_yield">
        <th mat-header-cell *matHeaderCellDef class="header-center" [ngStyle]="{'display': 'none'}">
          {{ "HarvestEstimation.TotalEstimationYield" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" align="center">
          {{ element.total_estimation_yield }}
        </td>
      </ng-container>

      <ng-container matColumnDef="total_e">
        <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">{{ "HarvestEstimation.TotalEstimationYield" | translate
          }}</th>
      </ng-container>

      <ng-container matColumnDef="total_actual_yield">
        <th mat-header-cell *matHeaderCellDef class="header-center" [ngStyle]="{'display': 'none'}">
          {{ "HarvestEstimation.TotalActualYield" | translate }}
        </th>
        <td mat-cell *matCellDef="let element" align="center">
          {{ element.total_actual_yield }}
        </td>
      </ng-container>

      <ng-container matColumnDef="total_a">
        <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">{{ "HarvestEstimation.TotalActualYield" | translate }}
        </th>
      </ng-container>

      <ng-container [matColumnDef]="col" *ngFor="let col of displayedColumns | slice:4">
        <th mat-header-cell *matHeaderCellDef>{{"HarvestEstimation." + col?.split('_')[0] | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element[col] ?? 0}}</td>
      </ng-container>

      <ng-container [matColumnDef]="group" *ngFor="let group of columnGroups | slice:4">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">{{ group }}</th>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnGroups"></tr>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

  </div>
  <div page-content class="data-not-found" *ngIf="dataSource?.length <= 0 && !isLoading">
    <img src="../../../../../assets/svgs/data-not-found.svg" alt="Image not found resource 404" />
    <span>{{ "NoResult" | translate }}</span>
  </div>
  <ng-container page-footer></ng-container>
</app-page-layout>